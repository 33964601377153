<template>
    <AppLayout>

        <CloakUser
            :is_admin="admin_data.is_admin"
            :cloak_name="admin_data.cloak_name"
        ></CloakUser>

        <div class="row">
            <div class="col col-sm col-12">
                <a class="btn btn-danger btn-sm margin5" style="float: right" href="/customer/home"><i class="fa fa-arrow-left"></i> Back</a>
            </div>
        </div>

        <div class="row">
            <div class="col col-sm col-12">
                <div class="row mb-3 mt-2">
                    <div class="col col-sm col-12">
                        <div class="card">
                            <div class="card-header">
                                <h6 style="text-align: center; font-weight: bold">CLIENT FINANCIAL</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col col-sm col-12">
                <div class="card">
                    <div class="card-header">
                        Financial Summary
                    </div>
                    <div class="card-body">

                        <template v-for="d in financial_summary">
                            <div class="row">
                                <div class="col col-sm col-5">
                                    <p>{{d.text}}</p>
                                </div>
                                <div class="col col-sm col-7">
                                    <p style="text-align: right">{{d.value}}</p>
                                </div>
                            </div>

                        </template>

                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col col-sm col-12">
                <div class="card">
                    <div class="card-header">
                        Financial Statements
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col col-sm col-6">
                                Statement Period
                            </div>
                            <div class="col col-sm col-6">
                                <MySelect
                                    :items="select_statement_period_items"
                                    v-model="financial_statement_period"
                                />
                            </div>
                        </div>
                        <div class="row" style="margin-top: 20px">
                            <div class="col col-sm col-12" >
                                <button class="btn btn-sm btn-success" @click="downloadStatement" style="float:right;" :disabled="financial_statement_period === null">
                                    <i class="fa fa-download"></i> Download
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>

import { displayNumber } from '../../utils.js';
import MySelect from "../../Components/InputComponent/Select.vue";
import CloakUser from "./Components/CloakUser.vue";
export default {
    components: {CloakUser, MySelect},

    props: ['admin_data'],

    data() {
        return {
            financial_summary: {
                last_month: {
                    text:'Last Month Gov Held Balance',
                    value: 'Fetching Data...'
                },

                gov_held_balance: {
                    text:'Gov Held Available Funds',
                    value: 'Fetching Data...'
                }
            },
            select_statement_period_items: [],
            financial_statement_period: null,
        }
    },

    methods: {
        displayNumber,
        fetchFinancialData() {
            axios.get("/customer/get_client_financial_data")
                .then((res) => {
                    let data = res.data

                    if (!data.status) {
                        alert('Error. Please contact ChungWah Support')
                    }
                    data = data.data
                    this.setSummaryData(data.financial_summary)
                    this.setStatementPeriodDropdown(data.statement_history)

                })
                .catch(error => {
                    alert('Error. Please contact ChungWah Support'+ error)
                });
        },
        downloadStatement() {
            if (this.financial_statement_period === null) {
                alert('Please select a period')
            }

            let vm = this


            axios.get("/customer/get_customer_financial_statement", {
                params: {
                    statement_id: this.financial_statement_period,
                }
            }).then(function (res) {
                const byteCharacters = atob(res.data.data.pdf);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                // Create a Blob from the byte array
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Create a link element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Financial Statement '+vm.financial_statement_period+'.pdf';

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger the download by simulating a click
                link.click();

                // Remove the link from the document
                document.body.removeChild(link);
            })
        },

        setSummaryData(data) {
            if (data.GovHeldBal > 0) {
                this.financial_summary.gov_held_balance.value = '$'+this.displayNumber(data.GovHeldBal)
            }
            else {
                this.financial_summary.gov_held_balance.value = 'N/A';
            }

            if (data.LastMthGovHeldBal > 0) {
                this.financial_summary.last_month.value = '$'+this.displayNumber(data.LastMthGovHeldBal)
            } else {
                this.financial_summary.last_month.value = 'N/A'
            }
        },

        setStatementPeriodDropdown(data) {
            let values = [];
            data.forEach(element => {
                let d = {
                    text: this.invoiceMonthsDisplay(element.invoice_month),
                    value: element.id
                }
                values.push(d)
            })
            this.select_statement_period_items = values;
        },

        invoiceMonthsDisplay(data) {
            const year = data.substring(0, 4);
            const month = data.substring(4, 6);
            const months = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const monthName = months[parseInt(month) - 1];
            return `${monthName} ${year}`;
        }
    },
    created() {
        this.fetchFinancialData()
    }
}
</script>