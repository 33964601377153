require('./bootstrap');

/*
|----------------------------------------------------------------
| Vue 2
|----------------------------------------------------------------
*/

import { App, plugin } from '@inertiajs/inertia-vue'
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(plugin)
import AppLayout from "./Layouts/AppLayout"
Vue.component('AppLayout', AppLayout)

import MyTimepicker from './Components/Common/Timepicker'
Vue.component('MyTimepicker', MyTimepicker)

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import store from './Stores/index'
import MyFormGroup from './Components/InputComponent/FormGroup'
import MyTextField from './Components/InputComponent/Text'

Vue.component('MyFormGroup', MyFormGroup);
Vue.component('MyTextField', MyTextField);

const el = document.getElementById('app')
new Vue({
    store: store,
    render: h => h(App, {
        props: {
            initialPage: JSON.parse(el.dataset.page),
            resolveComponent: name => require(`./Pages/${name}`).default,
        },
    }),
}).$mount(el)