<template>
    <TimePicker
        use12-hours
        @change="onChange"
    />
</template>

<script>
import { TimePicker } from "ant-design-vue"
export default {
    components: { TimePicker },
}
</script>