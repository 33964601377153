<template>
    <div>
        <div v-if="items.length != 0">
            <div v-if="clickToEdit==true && edit_mode==false">
                <a
                    class="click-to-edit"
                    @click="setEdit"
                >{{computedText}}</a>
            </div>
            <div v-else>
                <cool-select
                    menuDefaultPosition="bottom"
                    :menuDynamicPosition="false"
                    v-if="computedItems.length > 0"
                    :size="size"
                    single-line
                    :items="computedItems"
                    :itemText="itemText"
                    :itemValue="itemValue"
                    :value="computedValue"
                    :placeholder="placeholder"
                    @input="$emit('input', $event)"
                    @select="$emit('select', $event)"
                    :readonly="readonly"
                    :disabled="disabled"
                ></cool-select>
                <template v-if="name != null">
                    <input
                        type="hidden"
                        :name="name"
                        :value="value"
                    >
                </template>
                <span v-else>-- No Option --</span>
            </div>
        </div>
        <div v-else>
            <span class="text-secondary">{{placeholder_not_found!="" ? placeholder_not_found : '-- Option is Empty --' }}</span>
        </div>
    </div>
</template>

<script>
import { CoolSelect } from "vue-cool-select"
import { CoolSelectPlugin } from "vue-cool-select"

import 'vue-cool-select/dist/themes/bootstrap.css'

export default {
    name: "my-select",
    components: {
        "cool-select": CoolSelect,
    },
    props: {
        /*
            available mode:
            vue: use cool select (cannot set name)
            html: use bootstrap select
        */
        mode: {
            type: String,
            default: "vue",
        },
        name: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "sm",
        },
        value: {
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        placeholder_not_found: {
            type: String,
            default: "",
        },
        items: {
            type: Array,
            default: () => {
                return []
            },
        },
        itemText: {
            type: String,
            default: "text",
        },
        itemValue: {
            type: String,
            default: "value",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clickToEdit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            edit_mode: false,
        }
    },
    computed: {
        computedItems() {
            var string_key_items = []
            var items = this.items
            var item_value = this.itemValue

            for (let i in items) {
                var new_item = items[i]
                if (new_item[item_value] != null) {
                    new_item[item_value] = new_item[item_value].toString()
                    string_key_items.push(new_item)
                }
            }
            return string_key_items
        },
        computedValue() {
            if (this.value != null) {
                return this.value.toString()
            } else {
                return null
            }
        },
        computedText() {
            let text = this.getTextFromSelect(this.items, this.computedValue)
            return text ? text : this.placeholder
        },
    },
    methods: {
        setEdit() {
            this.edit_mode = true
        },
        getTextFromSelect(lists_to_search, value_to_search) {
            let filtered = this.filterObject(lists_to_search, 'value', value_to_search);
            if (filtered.length == 0) {
                return false;
            } else {
                return filtered[0].text;
            }
        },
        filterObject(lists_to_search, column_to_search, value_to_search) {
            if (lists_to_search == null) {
                return [];
            }
            let filtered = lists_to_search.filter((value) => {
                if (value[column_to_search] == value_to_search) {
                    return value;
                }
            });
            return filtered;
        },
    },
}
</script>

<style>
.IZ-select__input--readonly input {
    background: #e9ecef !important; /*same as bootstrap disable color code*/
}
.click-to-edit {
    text-decoration: underline dotted #3496f0;
}
</style>
