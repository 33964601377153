<template>
    <div>
        Feedback
        <center>

            <star-rating
                :rating="rating"
                @rating-selected="onRatingChange"
            ></star-rating>

            <textarea
                :value="rating_text"
                @input="onRatingTextChange"
                class="form-control"
                style="margin-top:20px"
                rows=5
            ></textarea>
        </center>
    </div>
</template>
<script>
import StarRating from "vue-star-rating"
export default {
    components: {
        StarRating,
    },
    props: ["rating", "rating_text"],
    methods: {
        onRatingChange(rating) {
            this.$emit("rating-change", rating)
        },
        onRatingTextChange(event) {
            this.$emit("rating-text-change", event.target.value)
        },
    },
}
</script>