import moment from "moment/moment";

export function get_day_text_from_id(day_id) {
    const days = {
        0: 'N/A',
        1: 'Sunday',
        2: 'Monday',
        3: 'Tuesday',
        4: 'Wednesday',
        5: 'Thursday',
        6: 'Friday',
        7: 'Saturday'
    };

    if (day_id === null) {
        return 'N/A';
    }

    return days[day_id] || 'N/A';
}

export function time_convert_to_12hours(time) {
    const momentTime = moment(time, 'HH:mm:ss');
    if (!momentTime.isValid()) {
        return 'Invalid time format';
    }
    return momentTime.format('hh:mm:ss A');
}

export function displayNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
