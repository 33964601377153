<template>
    <b-form-input
        :value="value"
        @input="$emit('input', $event)"
        :size="size"
        :placeholder="placeholder"
        autocomplete="off"
        :readonly="readonly"
        :name="name"
        v-b-tooltip.focus
        :title="title"
        :type="type"
    ></b-form-input>
</template>
<script>
export default {
    props: {
        value: [String, Number],
        size: {
            default: "sm",
        },
        placeholder: String,
        readonly: {
            default: false,
        },
        name: {
            default: "",
        },
        title: {
            default: "",
        },
        type: {
            default: "text",
        },
    },
}
</script>
