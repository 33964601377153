<template>
    <AppLayout>

        <CloakUser
            :is_admin="admin_data.is_admin"
            :cloak_name="admin_data.cloak_name"
        ></CloakUser>

        <div class="row">
            <div class="col col-sm col-12">
                <a class="btn btn-danger btn-sm margin5" style="float: right" href="/customer/home"><i class="fa fa-arrow-left"></i> Back</a>
            </div>
        </div>

        <div class="row">
            <div class="col col-sm col-12">
                <div class="row mb-3 mt-2">
                    <div class="col col-sm col-12">
                        <div class="card">
                            <div class="card-header">
                                <h6 style="text-align: center; font-weight: bold">SERVICE SCHEDULE</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-for="(plans, day_id) in master_service_plan">
            <div class="card" style="margin-bottom: 20px">
                <div class="card-header">
                    <h6>{{ get_day_text_from_id(day_id) }}</h6>
                </div>

                <div class="card-body">
                    <template v-for="plan in plans">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col col-sm col-12">
                                        <h6 class="text-primary" style="font-weight: bold">{{plan.activity_name}} [{{plan.service_name}}]</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-sm col-12">
                                        <h6 >{{ time_convert_to_12hours(plan.start_time)   }} - {{ time_convert_to_12hours(plan.end_time) }} </h6>
                                    </div>
                                </div>

                                <div class="row" v-if="plan.assignment_type === 'centre' || plan.assignment_type === 'TR-C'">
                                    <div class="col col-sm col-12">
                                        <h6 >Location: {{plan.location_name}} </h6>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col-sm col-12">
                                        <h7 >Support Worker: {{ plan.sw_name }} </h7>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </AppLayout>
</template>
<script lang="ts">

import { get_day_text_from_id, time_convert_to_12hours } from '../../utils.js';
import CloakUser from "./Components/CloakUser.vue";
export default {
    components: {CloakUser},
    props: ['master_service_plan', 'admin_data'],

    methods: {
        get_day_text_from_id,
        time_convert_to_12hours,

    }
}
</script>