<template>
    <div>
        <label class="cameraButton">Take a picture of communication book
            <image-uploader
                id="comBookImageUploader"
                :preview="true"
                capture="environment"
                doNotResize="gif"
                :autoRotate="true"
                outputFormat="blob"
                @input="setImage"
                :maxSize="0.8"
            >
            </image-uploader>
        </label>
    </div>
</template>

<script>
import ImageUploader from "vue-image-upload-resize"

export default {
    components: { ImageUploader },
    methods: {
        setImage: function (output) {
            this.$emit("input", output)
        },
    },
}
</script>

<style>
</style>