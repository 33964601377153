<template>
    <AppLayout>
        <div class="row">
            <div class="col col-sm col-12">
                <a class="btn btn-danger btn-sm margin5" style="float: right" href="/customer/home"><i class="fa fa-arrow-left"></i> Back</a>
            </div>
        </div>


        <div class="row">
            <div class="col col-sm col-12">
                <div class="row mb-3 mt-2">
                    <div class="col col-sm col-12">
                        <div class="card">
                            <div class="card-header">
                                <h6 style="text-align: center; font-weight: bold">{{ announcement.Title }}</h6>
                                <div class="icon"><i :class="priorityIcon(announcement.Priority)"></i></div>
                            </div>
                            <div class="card-body" >
                               <div v-html="announcement.Content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>




<script lang="ts">
export default {

    props:['announcement'],

    methods: {
        priorityIcon(priority) {

            let icon = '';

            if (priority == 3) {
                icon = 'fas fa-exclamation-triangle red';
            } else if (priority == 2) {
                icon = 'fas fa-star orange';
            } else if (priority == 1) {
                icon = 'fas fa-info-circle blue';
            } else {
                icon = 'fas fa-bullhorn';
            }
            return icon;
        }
    }

}
</script>