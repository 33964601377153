<template>
    <AppLayout>
        <div class="">
            <div class="row">
                <div class="col col-xl-5">
                    <h4>Documents</h4>
                </div>

                <div class="col col-xl-7">
                    <b-button style="float: right;" class="btn btn-sm btn-danger" @click="backClick">Back</b-button>
                </div>
            </div>

            <div class="card mt-2">
                <div class="card-body">
                    <!-- <div class="row">
                        <div class="col col-xl-3">
                            <label>Search</label>
                            <MyTextField />
                        </div>
                    </div> -->

                    <div class="row mt-5">
                        <div class="col col-xl-12">
                            <table class="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th style="width: 8%">Option</th>
                                        <th>Title</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="document in documents" style="padding-top: 10px; padding-bottom: 10px;">
                                        <td>
                                            <b-button class="btn btn-sm btn-success" @click="viewDocument(document.id)">
                                                <i class="fas fa-eye"></i>
                                            </b-button>
                                        </td>
                                        <td>{{ document.filename }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>

export default {

    props:['documents'],
    
    methods: {
        backClick() {
            window.location.href = "/home";
        },

        viewDocument(id) {
            let href = "/admin/document_management/view?id="+id
            window.open(href, '_blank').focus();
        },
    }
}

</script>