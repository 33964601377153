<template>
    <div :class="[divColClass, bottomPaddingClass,rowClass]">
        <div class="row">
            <label :class="labelColClass">
                {{ title }}

                <template v-if="hasInfoSlot">
                    <i
                        :id="'tooltip-info-'+component_random_id"
                        class="fa fa-info-circle"
                    ></i>
                    <b-tooltip
                        ref="tooltip"
                        :target="'tooltip-info-'+component_random_id"
                    >
                        <slot name="info"></slot>
                    </b-tooltip>
                </template>

                <span v-if="title!=''">:</span>
            </label>
            <div :class="inputColClass">
                <slot></slot>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        title: {
            type: String,
        },
        divCol: {
            type: Number,
        },
        labelCol: {
            default: 5,
        },
        inputCol: {
            default: 7,
        },
        bottomPadding: {
            default: 0,
        },
        mb: {
            default: 0,
        },
        withRow: {
            type: Boolean,
            default: false,
        },
        equalSize: {
            type: Boolean,
            default: false,
        },
        fullSize: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            component_random_id: null,
        }
    },
    computed: {
        bottomPaddingClass() {
            if (this.fullSize && this.bottomPadding == 0) {
                return "mb-2"
            }

            let bottom = this.bottomPadding
            if(this.mb != 0 ){
                bottom = this.mb
            }

            return "mb-" + bottom
        },
        divColClass() {
            var div_col = null
            if (this.divCol != null) {
                div_col = this.divCol
            }

            if (this.equalSize) {
                div_col = 12
            }

            if (this.fullSize) {
                div_col = null
            }

            if (div_col != null) {
                return "col-md-" + div_col
            } else {
                return ""
            }
        },
        labelColClass() {
            var label_col = this.labelCol
            if (this.equalSize) {
                label_col = 6
            }
            if (this.fullSize) {
                label_col = 12
            }
            return "col-md-" + label_col
        },
        inputColClass() {
            var input_col = this.inputCol
            if (this.equalSize) {
                input_col = 6
            }
            if (this.fullSize) {
                input_col = 12
            }
            return "col-md-" + input_col
        },
        rowClass() {
            if (this.withRow) {
                return "row"
            }
        },
        hasInfoSlot() {
            return !!this.$slots.info
        },
    },
    created() {
        this.component_random_id = this._uid
    },
}
</script>

