<template>
    <AppLayout>

        <CloakUser
            :is_admin="admin_data.is_admin"
            :cloak_name="admin_data.cloak_name"
        ></CloakUser>

        <div class="row">
            <div class="col col-sm col-12">
                <a class="btn btn-danger btn-sm margin5" style="float: right" href="/customer/home"><i class="fa fa-arrow-left"></i> Back</a>
            </div>
        </div>

        <div class="row">
            <div class="col col-sm col-12">
                <div class="row mb-3 mt-2">
                    <div class="col col-sm col-12">
                        <div class="card">
                            <div class="card-header">
                                <h6 style="text-align: center; font-weight: bold">SERVICE AGREEMENTS</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col col-sm col-12">
                <div class="card">
                    <div class="card-header">
                        Active Agreements
                    </div>
                    <div class="card-body">
                        <template v-if="this.agreement_details.active_agreements.length > 0">
                            <template v-for="agreement in this.agreement_details.active_agreements">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <template v-if="agreement.service_id === 2">
                                                    <h6 class="text-primary" style="font-weight: bold">{{agreement.service_name}} [Level {{agreement.level}}]</h6>
                                                </template>

                                                <template v-else>
                                                    <h6 class="text-primary" style="font-weight: bold">{{agreement.service_name}}</h6>
                                                </template>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <p>Start Date: {{agreement.start_date}}</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <p>End Date: {{agreement.end_date}}</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <p>Case Coordinator: {{agreement.case_worker_name}}</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </template>

                        </template>
                        <template v-else>
                            <div class="row">
                                <div class="col col-sm col-12">
                                    <p style="text-align: center">No Data...</p>
                                </div>
                            </div>

                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col col-sm col-12">
                <div class="card">
                    <div class="card-header">
                        Expired Agreements
                    </div>
                    <div class="card-body">
                        <template v-if="this.agreement_details.expired_agreements.length > 0">
                            <template v-for="agreement in this.agreement_details.expired_agreements">
                                <div class="card mb-2">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <template v-if="agreement.service_id === 2">
                                                    <h6 class="text-primary" style="font-weight: bold">{{agreement.service_name}} [Level {{agreement.level}}]</h6>
                                                </template>

                                                <template v-else>
                                                    <h6 class="text-primary" style="font-weight: bold">{{agreement.service_name}}</h6>
                                                </template>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <p>Start Date: {{agreement.start_date}}</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <p>End Date: {{agreement.end_date}}</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-sm col-12">
                                                <p>Case Coordinator: {{agreement.case_worker_name}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                        </template>
                        <template v-else>
                            <div class="row">
                                <div class="col col-sm col-12">
                                    <p style="text-align: center">No Data...</p>
                                </div>
                            </div>

                        </template>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>
<script lang="ts">
import CloakUser from "./Components/CloakUser.vue";

export default {
    components: {CloakUser},

    data() {
        return {
            agreement_details: {
                active_agreements: [],
                expired_agreements: []
            }
        }
    },

    props:['admin_data'],

    methods: {
        fetchAgreementData() {
            axios.get("/customer/get_customer_agreement_details")
                .then((res) => {
                    let data = res.data

                    if (!data.status) {
                        alert('Error. Please contact ChungWah Support')
                    }
                    this.agreement_details = res.data.agreement_details
                })
                .catch(error => {
                    alert('Error. Please contact ChungWah Support'+ error)
                });
        }
    },

    created() {
        this.fetchAgreementData();
    }
}
</script>