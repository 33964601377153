<template>
    <AppLayout>

        <CloakUser
            :is_admin="admin_data.is_admin"
            :cloak_name="admin_data.cloak_name"
        ></CloakUser>

        <div>
            <div class="row">
                <div class="col col-sm col-12">
                    <a class="btn btn-danger btn-sm margin5" style="float: right" href="/customer/home"><i class="fa fa-arrow-left"></i> Back</a>
                </div>
            </div>

            <div class="row mb-3 mt-2">
                <div class="col col-sm col-12">
                    <div class="card">
                        <div class="card-header">
                            <h6 style="text-align: center; font-weight: bold">PROFILE</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-sm col-12">
                    <div class="card">
                        <div class="card-header">
                            <b>Login Details</b>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col col-sm col-12">
                                    <p><b>Username: </b>{{profile_data.username}}</p>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col col-sm col-12">
                                    <p><b>Recent Activities</b></p>
                                    <table class="table table-bordered table-sm">
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                            <th>Summary</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-if="profile_data.history_log.length > 0">
                                            <template v-for="profile in profile_data.history_log">
                                                <tr>
                                                    <td>{{profile.Date}}</td>
                                                    <td>{{profile.Type}}</td>
                                                    <td>{{profile.Action}}</td>
                                                    <td>{{profile.Summary}}</td>
                                                </tr>
                                            </template>
                                        </template>

                                        <template v-else>
                                            <tr>
                                                <td>No Data...</td>
                                            </tr>
                                        </template>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col col-sm col-12">
                                    <a class="btn btn-sm btn-danger" href="/profile/change_password"  style="float: right"><i class="fa fa-key"></i> Change Password</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col col-sm col-12">
                    <div class="card">
                        <div class="card-header">
                            <b>Personal Info</b>
                        </div>

                        <div class="card-body">

                            <div class="row" v-if="is_loading">
                                <div class="col col-12">
                                    <p style="text-align: center">Loading Data....</p>
                                </div>
                            </div>



                            <div v-else class="row">
                                <div class="col col-12">
                                    <div class="row">
                                        <div class="col col-12 parent">
                                            <img class="child" :src="profile_data_form.photo_url"
                                                 height="200"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-12">
                                            <p class="property" >First Name</p>
                                            <p>{{profile_data_form.first_name}}</p>
                                        </div>

                                        <div class="col col-12">
                                            <p class="property" >Middle Name</p>
                                            <p>{{profile_data_form.middle_name}}</p>
                                        </div>

                                        <div class="col col-12">
                                            <p class="property" >Prefer Name</p>
                                            <p>{{profile_data_form.prefer_name}}</p>
                                        </div>

                                        <div class="col col-12">
                                            <p class="property" >Surname</p>
                                            <p>{{profile_data_form.surname}}</p>
                                        </div>

                                        <div class="col col-12">
                                            <p class="property" >Mobile</p>
                                            <p>{{profile_data_form.mobile}}</p>
                                        </div>

                                        <div class="col col-12">
                                            <p class="property" >Phone</p>
                                            <p>{{profile_data_form.phone}}</p>
                                        </div>

                                        <div class="col col-12">
                                            <p class="property" >Email</p>
                                            <p>{{profile_data_form.email}}</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col col-12">
                                            <button class="btn btn-sm btn-primary" @click="openEditDetailsModal" style="float:right;"><i class="fa fa-edit"></i> Edit Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal
                ref="edit-details-modal"
                hide-footer
                title="Edit Details"
            >
                <MyFormGroup
                    title="Mobile"
                    bottomPadding="2"
                    fullSize
                >
                    <MyTextField v-model="profile_data_form.mobile"/>
                </MyFormGroup>

                <MyFormGroup
                    title="Phone"
                    bottomPadding="2"
                    fullSize
                >
                    <MyTextField v-model="profile_data_form.phone"/>
                </MyFormGroup>

                <MyFormGroup
                    title="Email"
                    bottomPadding="2"
                    fullSize
                >
                    <MyTextField v-model="profile_data_form.email"/>
                </MyFormGroup>

                <b-button class="btn btn-success btn-sm" @click="updateDetails" style="float: right"><i class="fa fa-edit"></i> Update</b-button>
            </b-modal>
        </div>


    </AppLayout>
</template>


<style>
.parent {
    display: flex;
    height: 220px; /* Or whatever */
    //background-color: #000;
}

.child {
    //width: 100px;  /* Or whatever */
    height: 200px; /* Or whatever */
    margin: auto;  /* Magic! */
    //margin-top: 10px;
}

.property {
    font-weight: bold;
    margin-bottom: 5px;
}
</style>



<script lang="ts">

import CloakUser from "./Components/CloakUser.vue";

export default {
    components: {CloakUser},

    props:['profile_data', 'admin_data'],

    data() {
        return {
            profile_data_form: {
                first_name: null,
                middle_name: null,
                prefer_name: null,
                surname: null,
                phone: null,
                mobile: null,
                email: null,
                photo_url: null,
            },

            is_loading:false
        }
    },

    methods: {
        fetchProfileData() {
            axios.get("/customer/get_customer_profile_data")
                .then((res) => {
                    let data = res.data
                    if (!data.status) {
                        alert('Error. Please contact ChungWah Support')
                    }
                    this.profile_data_form = data.data.personal_particular
                    this.is_loading = false
                })
                .catch(error => {
                    alert('Error. Please contact ChungWah Support'+ error)
                });
        },

        openEditDetailsModal() {
            this.$refs["edit-details-modal"].show()
        },

        updateDetails() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const letterRegex = /[a-zA-Z]/;

            if ( this.profile_data_form.email !== null && this.profile_data_form.email !== '' &&  !emailRegex.test(this.profile_data_form.email)) {
                alert('Not a valid email address')
                return
            }

            if ( this.profile_data_form.phone !== null && this.profile_data_form.phone !== '' && letterRegex.test(this.profile_data_form.phone)) {
                alert('Not a valid phone number')
                return
            }

            if (  this.profile_data_form.mobile !== null && this.profile_data_form.mobile !== '' && letterRegex.test(this.profile_data_form.mobile)) {
                alert('Not a valid phone number')
                return
            }

            this.is_loading = true

            axios.post("/customer/update_profile_data", {
                email: this.profile_data_form.email,
                phone: this.profile_data_form.phone,
                mobile: this.profile_data_form.mobile
            }).then(res => {
                let data = res.data
                if (!data.status) {
                    alert('Error. Please contact ChungWah Support')
                }


                alert('Successfully Updated')

                this.$refs["edit-details-modal"].hide()

                this.profile_data_form = data.data.personal_particular
                this.is_loading = false
            })



        }
    },

    created() {
        this.is_loading = true
        this.fetchProfileData()
    }

}
</script>