<template>
    <AppLayout title="Document Management">
        <div class="">
            <div class="row">
                <div class="col col-xl-5">
                    <h4>Document Management</h4>
                </div>

                <div class="col col-xl-7">
                    <b-button style="float: right;" class="btn btn-sm btn-danger" @click="backClick">Back</b-button>
                </div>
            </div>

            <div class="card mt-2">
                <div class="card-body">
                    <div class="row">
                        <!-- <div class="col col-xl-3">
                            <label>Search</label>
                            <MyTextField />
                        </div> -->

                        <div class="col col-xl-12">
                            <!-- <b-button class="btn btn-success mt-4 ml-3" style="float: right;">
                                <i class="fas fa-file-upload"></i>  Upload
                            </b-button>
                            <b-form-file
                                style="float: right; width: 50%;"    
                                class="mt-4"    
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                            ></b-form-file> -->

                            <b-button class="btn btn-sm btn-success" @click="openUploadModal" style="float: right;"><i class="fas fa-plus"></i> Document</b-button>
                            
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col col-xl-12">
                            <table class="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th style="width: 12%">Option</th>
                                        <th style="width: 30%">Title</th>
                                        <th>SW Portal</th>
                                        <th>Client Portal</th>
                                        <th>File Size</th>
                                        <th>Uploaded At</th>
                                        <th>Uploaded By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="document in documents" style="padding-top: 10px; padding-bottom: 10px;">
                                        <td>
                                            <b-button class="btn btn-sm btn-success" @click="viewDocument(document.id)">
                                                <i class="fas fa-eye"></i>
                                            </b-button>
                                            <b-button class="btn btn-sm btn-primary" @click="openEditModal(document)">
                                                <i class="fas fa-edit"></i>
                                            </b-button>
                                            <b-button class="btn btn-sm btn-danger" @click="deleteDocument(document.id)">
                                                <i class="fas fa-trash"></i>
                                            </b-button>
                                        </td>
                                        <td>{{ document.filename }}</td>
                                        <td>{{ isTrue(document.is_staff) }}</td>
                                        <td>{{ isTrue(document.is_client) }}</td>
                                        <td>{{ bytesToMB(document.filesize) }}</td>
                                        <td>{{ document.uploaded_at }}</td>
                                        <td>{{ document.uploaded_name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal
                ref="document-upload-modal"
                hide-footer
                size="m"
                title="Document Upload"
            >
                <MyFormGroup
                    title="Display On"
                    bottomPadding="2"
                    :labelCol="3"
                    :inputCol="8"
                >
                    <div class="d-flex align-items-center">
                        <b-form-checkbox class="mr-3" v-model="file_upload_form.is_staff">Support Worker Portal</b-form-checkbox>
                        <b-form-checkbox v-model="file_upload_form.is_client">Client Portal</b-form-checkbox>
                    </div>
                    
                </MyFormGroup>

                <MyFormGroup
                    title="Document"
                    bottomPadding="2"
                    fullSize
                >
                    <b-form-file  
                        :disabled="file_upload_form.is_edit"                      
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        v-model="file_upload_form.file"
                        ref="file"
                    ></b-form-file>
                </MyFormGroup>

                <b-button class="btn btn-sm btn-success" v-if="!file_upload_form.is_edit" style="float: right" @click="uploadDocument">Upload</b-button>
                <b-button class="btn btn-sm btn-success" v-if="file_upload_form.is_edit" style="float: right" @click="submitChanges">Submit</b-button>
                <b-button class="btn btn-sm btn-danger mr-2" style="float: right" @click="closeModal">Close</b-button>
            </b-modal>
        </div>
    </AppLayout>
</template>

<script>
export default {

    props:['documents'],

    data() {
        return {
            file_upload_form: {
                file: null,
                is_staff: 0,
                is_client: 0,
                is_edit:false,
                id: null
            }
        }
    },
    methods: {
        bytesToMB(bytes) {
            if (isNaN(bytes) || bytes < 0) {
                throw new Error("Input must be a non-negative number.");
            }
            const mb = bytes / (1024 * 1024);
            return parseFloat(mb.toFixed(2)) + ' MB';
        },

        viewDocument(id) {
            let href = "/admin/document_management/view?id="+id
            window.open(href, '_blank').focus();
        },

        async deleteDocument(id) {

            let c = await this.$swal({
                    icon: 'info',
                    title: "Confirmation",
                    text: "Confirm to delete document ?",
                    showCancelButton: true,
            });

        
            if (c.value) {
                axios.get("/admin/document_management/delete", {
                    params: {
                        id: id,
                    }
                }).then(function (res) {
                    location.reload()
                })
            }

        },

        openUploadModal() {
            this.file_upload_form.is_client = false
            this.file_upload_form.is_staff = false
            this.file_upload_form.is_edit = false;
            this.file_upload_form.id = null
            this.$refs["document-upload-modal"].show()
        },

        openEditModal(document) {
            this.file_upload_form.is_client = document.is_client ? true : false
            this.file_upload_form.is_staff = document.is_staff ? true : false
            this.file_upload_form.is_edit = true;
            this.file_upload_form.id = document.id
            this.$refs["document-upload-modal"].show()
        },

        closeModal() {
            this.$refs["document-upload-modal"].hide()
        },

        submitChanges() {
            if (!this.file_upload_form.is_staff && !this.file_upload_form.is_client) {
                alert('Please select a display type')
                return
            }

            let formData = new FormData()
            formData.append("is_staff", this.file_upload_form.is_staff)
            formData.append("is_client", this.file_upload_form.is_client)
            formData.append("id", this.file_upload_form.id)
            axios.post(
                "/admin/document_management/update",
                formData
                )
                .then((res) => {
                    let data = res.data
                    if (data.status) {
                    //     location.reload()
                    }  
                    else {
                        alert('Error')
                    }
                })
        },

        setFile(){
            this.file_upload_form.file = this.$refs.file.files[0];
        },

        uploadDocument() {

            if (this.file_upload_form.file === null) {
                alert('Please select a file')
                return
            }

            if (!this.file_upload_form.is_staff && !this.file_upload_form.is_client) {
                alert('Please select a display type')
                return
            }

            let formData = new FormData()
            formData.append("filename", this.file_upload_form.file)
            formData.append("is_staff", this.file_upload_form.is_staff)
            formData.append("is_client", this.file_upload_form.is_client)
            
            axios.post(
                "/admin/document_management/upload",
                formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((res) => {
                    location.reload()   
                })
                    
        },

        isTrue(val) {
            if (val == 1) {
                return '✔'
            }

            return '✗'
        },

        backClick() {
            window.location.href = "/home";
        }

    },

    
}
</script>
