window._ = require('lodash');
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');
require('moment');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';



// CommonJS
window.Swal = require('sweetalert2')



/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});
$(function () {
    $('.collapse').on('shown.bs.collapse', function () {
        $(this).closest('.card').find(".dropdown-indicator").addClass('fa-minus').removeClass(
            'fa-plus');
    });
    $('.collapse').on('hidden.bs.collapse', function () {
        $(this).closest('.card').find(".dropdown-indicator").addClass('fa-plus').removeClass(
            'fa-minus');
    });
});
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

//AXIOS INTERCEPTORS
axios.interceptors.response.use((response) => {
    //TODO SUCCESS HANDLING
    return response
}, function (err) {
    //TODO ERROR HANDLING
    hideLoading();
    let error_status = err.response.status ?? 'N /A';
    let error_message = err.response.data.message ?? 'N /A';
    Swal.fire({
        title: error_status,
        text: error_message,
        icon: 'error',
        confirmButtonText: 'OK'
    })
    return Promise.reject(err)
})
